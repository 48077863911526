<template>
    <LoadedContent class="page-view" :class="template.item_key" :loaded="isLoaded" :error="isError" :tag="'article'">
        <h1 v-if="page.header">
            {{page.header}}
        </h1>
        <TemplateDefault  ref="pageEl" v-if="templateType === 'default'" :page="page" :pagePlacementComponent="pagePlacement"/>
    </LoadedContent>
</template>

<script>
import { computed, watchEffect, defineAsyncComponent, provide, ref, onUpdated } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import LoadedContent from '@common/components/base/LoadedContent';
import usePage from '@common/composable/cms/Page';
import TemplateDefault from '@common/components/cms/templates/TemplateDefault';


export default {
    name: "PageView",
    components: {
        LoadedContent,
        TemplateDefault
    },
    props: {
        link: {
            type: String,
            default: () => null,
        },
        code: {
            type: String,
            default: () => null,
        },
        customBlocks: {
            type: Object,
            default: () => {},
        },
        offset: {
            type: Number,
            default: () => 60,
        }
    },
    setup(props){
        provide('customBlocks',props.customBlocks);

        const store = useStore();

        const router = useRouter();
        
        const pageEl = ref(null);

        const link = computed(() => {
            if(props.link){
                return props.link;
            } else if(router.currentRoute.value.params.page_url){
                return router.currentRoute.value.params.page_url;
            }
            return null;
        })
        const code = computed(() => {
            if(props.code){
                return props.code;
            } else if(router.currentRoute.value.params.page_code){
                return router.currentRoute.value.params.page_code;
            }
            return null;
        })

        const scrollTo = (refName) => {
            if(!pageEl.value){
                return;
            } else if(!refName){
                window.scrollTo(0,0);
                return;
            }
            setTimeout(() => {
                const element = document.getElementById(refName.replace(/#/, ''));
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - props.offset;
                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                });
                // if (element && element.scrollIntoView) {
                //   element.scrollIntoView({ behavior: 'smooth'})
                // }
            },100);
        }
        onUpdated(() => scrollTo(router.currentRoute.value.hash));

        const loadPage = () => store.dispatch('page/fetchPage',{code: code.value,link: link.value});

        watchEffect(() => loadPage());
        
        const page = computed(() => store.getters['page/getPage']);
        const { templateType } = usePage(page);

        const contents = computed(() => store.getters['page/getContents']); 


        const getContents = (placementCode) => {
            let _contents = [];

            for(let _content of contents.value){
                if(_content.placementCode === placementCode){
                    _contents.push(Object.assign({},_content));
                }
            }

            return _contents;
        }

        const template = computed(() => store.getters['page/getTemplate']);

        const isLoaded = computed(() => store.getters["page/isLoaded"]);
        
        const isError = computed(() => store.getters["page/isError"]);

        // const i18n = useI18n();
        // PageMeta(i18n,store)

        const pagePlacement = defineAsyncComponent(() => import(/* webpackChunkName: "page-placement" */'@common/components/cms_app/PagePlacement'));
      
        return {
            contents,
            getContents,
            template,
            isLoaded,
            isError,
            pagePlacement,
            page,
            templateType,
            pageEl
        };

    }
}
</script>

<style scoped>
.page-view{
    min-height: 50vh;
}
</style>